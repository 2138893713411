import { mapState } from "vuex";
import textParser from "common/libs/textParser";

export default {
  props: ["message", "index"],
  data: function() {
    const constObj = this.$store.state.constObj;
    return {
      botIcon: constObj.HAS_BOT_ICON ? this.getBotIcon() : "",
      isBot: this.message.from != "user",
      botName: constObj.BOT_NAME,
      hasBotIcon: constObj.HAS_BOT_ICON,
      fromWhom: this.message.from === "user" ? "user" : "bot",
      showChoiceButtonLeftIcon: constObj.SHOW_CHOICE_BUTTON_LEFT_ICON,
      showChoiceButtonRightIcon: constObj.SHOW_CHOICE_BUTTON_RIGHT_ICON,
      showDefaultLeftIcon: constObj.SHOW_DEFAULT_LEFT_ICON,
      showDefaultRightIcon: constObj.SHOW_DEFAULT_RIGHT_ICON
    };
  },
  computed: {
    firstMessage() {
      return (
        this.index === 0 &&
        this.$store.state.messagesHandler.messages.length === 1
      );
    },
    showMessageArea() {
      if (this.$store.state.constObj.SHOW_SINGLE_CHOICE) return true;
      // 現在のメッセージの選択肢が1つだけの場合、メッセージエリアを表示しない
      const currentHasSingleChoice = this.message.content.choices && Object.keys(this.message.content.choices).length === 1;

      // ひとつ前のメッセージの選択肢が1つだけの場合、メッセージエリアを表示しない
      const prevMessage = this.$store.state.messagesHandler.messages[this.index - 1];
      const prevHasSingleChoice = prevMessage && prevMessage.content.choices && Object.keys(prevMessage.content.choices).length === 1;
      return !currentHasSingleChoice && !prevHasSingleChoice;
    },
    ...mapState(["constObj"])
  },
  mounted() {
    this.$nextTick(() => {
      // 次の選択肢が1つだけの場合、自動的にその選択肢を選択する
      if (
        this.message.content.choices && 
        Object.keys(this.message.content.choices).length === 1
      ) {
        const suspendTime = this.$store.state.constObj.SHOW_SINGLE_CHOICE ? 1000 : 0;
        // 1000ms 待ってから選択する
        setTimeout(() => {
          const keyString = Object.keys(this.message.content.choices)[0];
          const key = parseInt(keyString, 10);
          const choice = this.message.content.choices[key];
          this.selectAnswer(choice, key);
        }, suspendTime);
      }
    });
  },
  methods: {
    selectAnswer(choice, key) {
      this.$emit("selectAnswer", choice, key, this.index);
    },
    splitText() {
      let texts;
      const refId = textParser.extractRefId(this.message.content.text);

      // 回答に{ref_id=xxxx}が含まれている場合、参照先のテキストを取得する
      if (refId && this.$store.state.resources.scenario.step[refId]) {
        const refTextContent = this.$store.state.resources.scenario.step[refId].action.success.value;
        texts = textParser.parseButton(refTextContent).text.split("<<>>");
      } else {
        texts = this.message.content.text.split("<<>>");
      }

      const lineBreakDeleteTexts = texts.map(t =>
        t.replace(/^(\r?\n)+|(\r?\n)+$/g, "")
      );
      const result = [];
      lineBreakDeleteTexts.forEach((element) => {
        result.push(this.supplementAnchorTag(element));
      });
      return result;
    },
    supplementAnchorTag(text) {
      let result = text;
      let matchTextList = text.match(
        /[^\"\'>]https?(:\/\/[-_.!~*\'()a-zA-Z0-9;\/?:\@&=+\$,%#]+)/g
      );
      if (matchTextList) {
        let resultText = text;
        matchTextList.forEach(element => {
          const linkText = [element].filter(s => !s.match(/["']/g));
          if (linkText.length > 0) {
            const anchorTagLinkText =
              '<a href="' + linkText + '" target="_blank">' + linkText + "</a>";
            resultText = resultText.replace(linkText, anchorTagLinkText);
          }
        });
        result = resultText;
      }
      return result;
    },
    getIconFileName(defaultBotIcon) {
      let iconName = defaultBotIcon;
      const message = this.message.content.text.match(/<emotion:.+?>/);
      if (message) {
        const iconTag = message[0].split(/<emotion:|>/);
        if (iconTag.length >= 2) {
          iconName = iconTag[1];
        }
      }
      return iconName;
    },
    getBotIcon() {
      const constObj = this.$store.state.constObj;
      const defaultBotIcon = constObj.DEFAULT_BOT_ICON;
      const hasBotIcon = constObj.HAS_BOT_ICON;
      const iconName = this.getIconFileName(defaultBotIcon);
      let result = "";
      if (hasBotIcon) {
        result = require("@product/resource/" + iconName + ".png");
      }
      return result;
    }
  }
};
