export default {
  parseButton(text) {
    const regexp = new RegExp(/<button:[\s\S]*?>/g);
    const buttonTags = text.match(regexp) || [];
    const choices = buttonTags.map(tag => {
      return tag.match(/<button:\d{1,3}.(.*)>/)[1];
    });

    return {
      text: text.replace(regexp, "").trim(),
      choices
    };
  },
  parseItemTexts(items) {
    const choices = {};
    items.forEach(item => {
      const faqId = item.items.log_faq;
      choices[faqId] = item.text;
    });
    return choices;
  },
  extractRefId(str) {
    // 正規表現でref_idの値を抽出
    const match = str.match(/ref_id=([^}'\s]+)/);
    
    // マッチした場合は値を返す、しなければnullを返す
    return match ? match[1] : null;
  }
};
