var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.showMessageArea
    ? _c(
        "div",
        {
          staticClass: "scichat-msg-area",
          class: _vm.fromWhom,
          attrs: { id: "message" + _vm.index },
        },
        [
          _vm.isBot
            ? _c("div", { staticClass: "scichat-msg-area__box" }, [
                _c("div", { staticClass: "scichat-msg-area__box__header" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "scichat-msg-area__box__header__name" },
                    [_vm._v(_vm._s(_vm.botName))]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "scichat-msg-area__box__content" }, [
                  _vm.message.status !== "last_step"
                    ? _c(
                        "div",
                        { staticClass: "scichat-msg-area__box__content__time" },
                        [_vm._v(_vm._s(_vm.message.displayed_time))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.message.status === "last_step"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "scichat-msg-area__box__content__balloon-last__area",
                        },
                        [
                          _c("div", {
                            staticClass:
                              "scichat-msg-area__box__content__balloon-last__icon",
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "scichat-msg-area__box__content__balloon-last",
                            },
                            [
                              _c("p", {
                                domProps: {
                                  innerHTML: _vm._s(this.message.content.text),
                                },
                              }),
                            ]
                          ),
                        ]
                      )
                    : _c(
                        "div",
                        {
                          staticClass:
                            "scichat-msg-area__box__content__passage__balloon",
                        },
                        [
                          _c("p", {
                            staticClass:
                              "scichat-msg-area__box__content__passage__balloon__text",
                            domProps: {
                              innerHTML: _vm._s(_vm.message.content.text),
                            },
                          }),
                        ]
                      ),
                  _vm._v(" "),
                  _vm.message.status === "feedback"
                    ? _c(
                        "div",
                        {
                          staticClass: "scichat-msg-area__box__content__choice",
                        },
                        _vm._l(
                          _vm.message.content.choices,
                          function (choice, key) {
                            return _c(
                              "div",
                              {
                                key: key,
                                staticClass:
                                  "scichat-msg-area__box__content__choice-botton__ref",
                                attrs: { disabled: _vm.message.isDisabled },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.selectAnswer(choice, key)
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "scichat-msg-area__box__content__choice-botton__area",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "scichat-msg-area__box__content__choice-botton__text",
                                      },
                                      [_vm._v(_vm._s(choice))]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      )
                    : _c(
                        "div",
                        {
                          staticClass: "scichat-msg-area__box__content__choice",
                        },
                        _vm._l(
                          _vm.message.content.choices,
                          function (choice, key) {
                            return _c(
                              "div",
                              {
                                key: key,
                                staticClass:
                                  "scichat-msg-area__box__content__choice-botton",
                                class: _vm.message.isDisabled && "disabled",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.selectAnswer(choice, key)
                                  },
                                },
                              },
                              [
                                _vm.showChoiceButtonLeftIcon
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "scichat-msg-area__box__content__choice-botton__left-icon",
                                      },
                                      [
                                        _vm.showDefaultLeftIcon
                                          ? _c("font-awesome-icon", {
                                              attrs: { icon: "angle-right" },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.showChoiceButtonLeftIcon
                                  ? _c("span", {
                                      staticClass:
                                        "scichat-msg-area__box__content__choice-botton__space",
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "scichat-msg-area__box__content__choice-botton__choice",
                                  },
                                  [_vm._v(_vm._s(choice))]
                                ),
                                _vm._v(" "),
                                _vm.showChoiceButtonRightIcon
                                  ? _c("span", {
                                      staticClass:
                                        "scichat-msg-area__box__content__choice-botton__space",
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.showChoiceButtonRightIcon
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "scichat-msg-area__box__content__choice-botton__right-icon",
                                      },
                                      [
                                        _vm.showDefaultRightIcon
                                          ? _c("font-awesome-icon", {
                                              attrs: { icon: "angle-right" },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.isBot
            ? _c("div", { staticClass: "scichat-msg-area__box" }, [
                _c("div", { staticClass: "scichat-msg-area__box__content" }, [
                  _c(
                    "div",
                    { staticClass: "scichat-msg-area__box__content__time" },
                    [_vm._v(_vm._s(_vm.message.displayed_time))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "scichat-msg-area__box__content__passage__balloon",
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "scichat-msg-area__box__content__passage__balloon__text",
                        },
                        [_vm._v(_vm._s(_vm.message.content.text))]
                      ),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "scichat-msg-area__box__header__icon" }, [
      _c("img", {
        attrs: { src: require("@product/resource/bot_icon.png"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }